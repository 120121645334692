<template>
  <div class="create-category-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Schedule' }"
        >檔期管理</el-breadcrumb-item
      >
      <el-breadcrumb-item :to="{ name: 'ScheduleLocation' }"
        >展區管理
      </el-breadcrumb-item>
      <el-breadcrumb-item>新增展區</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <el-form
        ref="workForm"
        :model="schedule_form"
        :rules="schedule_rules"
        label-position="top"
      >
        <el-form-item label="中文名稱" prop="name_zh">
          <el-input
            v-model="schedule_form.name_zh"
            maxlength="64"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="英文名稱" prop="name_en">
          <el-input
            v-model="schedule_form.name_en"
            maxlength="64"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="flex-end">
            <el-button type="warning" @click="handleRedirect">回主頁</el-button>
            <el-button type="success" @click="handleSubmit">送出</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { createScheduleLocation } from "@/api/schedule-location";

export default {
  name: "CreateScheduleLocation",
  data() {
    return {
      schedule_form: {
        name_zh: "",
        name_en: "",
      },
      schedule_rules: {
        name_zh: [
          { required: true, message: "請填入名稱", trigger: "blur" },
          { max: 64, message: "長度太長", trigger: "blur" },
        ],
        name_en: [
          { required: true, message: "請填入名稱", trigger: "blur" },
          { max: 64, message: "長度太長", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    handleSubmit() {
      this.$refs.workForm.validate(async (valid) => {
        if (valid) {
          await createScheduleLocation(this.schedule_form);

          this.$message({
            type: "success",
            message: "創建成功",
          });

          await this.handleRedirect();
        }
      });
    },
    async handleRedirect() {
      await this.$router.push({ name: "ScheduleLocation" });
    },
  },
};
</script>

<style lang="scss" scoped></style>
